<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 9 9"
  >
    <path
      fill="currentColor"
      d="M6.622.21 4.091 2.741 1.559.21C1.28-.07.826-.07.547.21L.21.547c-.28.279-.28.733 0 1.012l2.531 2.532L.21 6.622c-.28.279-.28.733 0 1.012l.337.338c.279.279.733.279 1.012 0l2.532-2.531 2.531 2.531c.279.279.733.279 1.012 0l.338-.338c.279-.279.279-.733 0-1.012L5.441 4.091l2.531-2.532c.279-.279.279-.733 0-1.012L7.634.21c-.279-.28-.733-.28-1.012 0Z"
    />
  </svg>
</template>

<script>
  export default {
    name: 'CloseIcon'
  }
</script>
